import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import project from '../../assets/images/projects/patac.jpg'
import project1 from '../../assets/images/projects/patac1.jpg'
import project2 from '../../assets/images/projects/patac2.jpg'
import project3 from '../../assets/images/projects/patac3.jpg'
import {Link} from 'gatsby'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Croce Rossa Italiana" 
                homePageText="Case management" 
                homePageUrl="/beneficiary-management" 
                activePageText="Croce Rossa Italiana" 
            />
            <CaseStudiesDetailsContent />
            <Footer />
        </Layout>
    );
}
export default CaseDetails

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Partner:</span>
                        <a href="https://cri.it/" target="_blank" rel="noreferrer">Croce Rossa Italiana</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
                        Italy 
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>Technology:</span>
                        Dino
                        </li>
                    
                    <li>
                        <div className="icon">
                            <i className='bx bx-money'></i>
                        </div>
                        <span>Donor:</span>
	    		Private funds
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-time'></i>
                        </div>
                        <span>Duration:</span>
                        1 year 
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-check'></i>
                        </div>
                        <span>Status:</span>
                        On going
                    </li>
                </ul>
            </div>
        </div>
    )
}

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={project} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Case management</span>
                            <h3>The project</h3>
	    <p>
Development of a data collection and analysis platform based on the DINO software, which allows the creation of forms, dashboards and reports to strengthen CRI's capacity to respond to the needs of populations affected by natural or man-made disasters, through vulnerability assessment in potential areas of intervention, monitoring of interventions and management of the distribution of goods and services.<br/> 
Thus, a system was developed to facilitate and harmonize the different phases of population assistance interventions, and in particular:<br/>
The mapping of vulnerabilities and relevant context information in the pre-disaster phase;<br/>
The monitoring of the interventions carried out according to the action plan prepared in response to an emergency, facilitating the rapid assessment of the needs of the population in the event phase and thus the identification of priorities.<br/>
Management of the supply chain, distribution of goods and the beneficiary with tools that can also support asset tracking, accountability and donor/stakeholder relations.<br/>

</p>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
	                </div>
        </section>
    )
}
